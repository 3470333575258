/* @import url("https://db.onlinewebfonts.com/c/8cffe8e4619a1b925e5293a0c76f078c?family=JackArmstrongBB-BoldItalic"); */

/* @font-face {
  font-family: "Jack";
  src: url("./font.woff") format("woff"), url("./font.woff") format("woff");
 
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "jack", "Roboto", sans-serif !important;
  /* font-size: xx-small; */
  font-size: small;
  scroll-behavior: smooth;
}
:root {
  --nav-max-height: 5rem;
  /* colors */
  --primary-100: #d5cfe0;
  --primary-200: #ab9fc1;
  --primary-300: #8270a3;
  --primary-400: #584084;
  --primary-500: #2e1065;
  --primary-600: #250d51;
  --primary-700: #1c0a3d;
  --primary-800: #120628;
  --primary-900: #090314;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;

  --grey-50: #f8fafc;
  --grey-100: #fbf9ff;
  --grey-200: #f8f4ff;
  --grey-300: #f4eeff;
  --grey-400: #f1e9ff;
  --grey-500: ##ede3ff;
  --grey-600: #beb6cc;
  --grey-700: #8e8899;
  --grey-800: #5f5b66;
  --grey-900: #2f2d33;

  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: "jack", "Roboto", sans-serif;
  --bodyFont: "Nunito", sans-serif;
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.1s ease-in-out all;
  /* --max-width: 1120px; */
  --max-width: 1300px;
  --fixed-width: 600px;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);

  --shadow-5: -1px 5px 16px 4px rgba(0, 0, 0, 0.5);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* text shadow */
  --textShadow: 1px 1px 2px black;
}

p {
  margin-bottom: 0.5rem;
  /* max-width: 40em; */
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
  font-weight: 900;
  @apply text-xs;
}
.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}
.btn:disabled {
  opacity: 0.5;
}
.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btn-danger {
  background: var(--red-light);
  color: var(--red-dark);
}
.btn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}
.btn-hipster:hover {
  color: var(--grey-100);
  background: var(--primary-700);
}
.btn-block {
  width: 100%;
}
.btn:hover {
  transform: scale(1.04) translateY(-2px);
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90%;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 1.5rem;
  margin: 1rem auto;
}
.form-label {
  display: block;
  font-size: var(--small-text);
  /* margin-bottom: 0.5rem; */
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
  font-weight: 900;
}
.form-input,
.form-textarea {
  width: 95%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
  height: 40px;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  margin: 0.5rem 0;
  background-color: #f5f5f5;
  background-color: var(--grey-50);
  transition: all 0.2s ease-in;
}

.form-row {
  margin-bottom: 1rem;
  width: 100%;
  margin: auto;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-input-disabled {
  margin-bottom: 1rem;
  text-align: center;
  max-width: 50%;
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* Switch element */
.form-switch {
  @apply relative select-none;
  width: 44px;
}

.form-switch label {
  @apply block overflow-hidden cursor-pointer h-6 rounded-full;
}

.form-switch label > span:first-child {
  @apply absolute block rounded-full;
  width: 18px;
  height: 18px;
  top: 2px;
  left: 2px;
  right: 50%;
  /* bottom: 2px; */
  transition: all 0.15s ease-out;
}

.form-switch input[type="checkbox"]:checked + label {
  @apply bg-[var(--primary-500)] p-3;
}

.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 22px;
}

.form-switch input[type="checkbox"]:disabled + label {
  @apply cursor-not-allowed bg-gray-100 dark:bg-gray-700/20 border border-gray-200 dark:border-gray-700/60;
}

.form-switch input[type="checkbox"]:disabled + label > span:first-child {
  @apply bg-gray-400 dark:bg-gray-600;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 0.6s linear infinite;
}
.rotate {
  border: 5px solid var(--grey-50);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}
.loading {
  margin: 0 auto;
}

.sub__title {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  text-shadow: var(--textShadow);
  font-size: min(2vw + 0.5rem, 2rem);
}
/* default style end */
body {
  letter-spacing: 1px !important;
  font-family: "jack", "Roboto", sans-serif !important;

  font-size: min(2vw, x-large);
  color: #2a2f5b !important;

  background-color: var(--backgroundColor) !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
div::-webkit-scrollbar {
  display: none;
}
select {
  background-color: var(--primary-400) !important;
}
a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
}
img {
  max-width: 100%;
}
h1,
h2,
h3 {
  font-family: var(--headingFont);
}
/* button {
  text-transform: uppercase !important;
  border-radius: var(--borderRadius);
  border: none;
  padding: 0.3em;
  background-color: var(--primary-500);
  padding: 0.5em;
  margin: 1rem;
  color: var(--white);
  box-shadow: var(--shadow-4);
  transition: var(--transition);
} */
@media (min-width: 600px) {
  button:hover {
    opacity: 0.9;
    transform: translateY(-3px);
  }
}
.title {
  @apply text-2xl font-extrabold text-transparent text-center md:text-4xl bg-clip-text bg-gradient-to-r from-purple-600 to-pink-400;
  text-transform: uppercase;
}
.container {
  max-width: var(--maxWidth);
  width: 90%;
  margin: auto;
}
.logo {
  max-width: 4rem;
  border: 100px;
}
svg {
  display: inline;
}

.marquee {
  /* line-height: 50px; */
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  margin: auto;
}
.marquee p {
  display: inline-block;
  padding-left: 10%;
  animation: marquee 15s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.skewed {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--grey-200);
  z-index: 0;
  transform: skewY(10deg);
  transform-origin: top right;

  z-index: -9;
}
.underline {
  @apply underline-offset-4;
  text-decoration-color: var(--red-dark);
}
/* .card {
  @apply w-11/12 max-w-2xl border rounded-md p-4 mb-2 text-center shadow-md md:m-auto;
} */
.card {
  @apply w-[95%] max-w-2xl rounded-md p-4 mb-2 text-center shadow-lg md:m-auto md:hover:shadow-2xl hover:cursor-pointer;
}
